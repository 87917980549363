import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { createRoot } from 'react-dom/client';

import { makeApp, defaultThemeClassName } from '@electra-one/app';
import { EditorConfig } from '@electra-one/config';
import { FirebaseElectraStorage } from '@electra-one/storage';

import '@electra-one/components/lib/style.css';
import '@electra-one/console/lib/style.css';
import '@electra-one/app/lib/style.css';

import electraOneProductPictureUrl from './images/product/electra-transparent-medium.png';

const appElement = document.getElementById('app');

if (appElement === null) {
  throw new Error('App element is null. This is probably a bug.');
}

declare global {
  interface Window {
    __EDITOR_CONFIG__?: Omit<EditorConfig, 'electraOneProductPictureUrl'>;
  }
}

if (
  window.__EDITOR_CONFIG__ === undefined ||
  window.__EDITOR_CONFIG__ === null
) {
  throw new Error('Configuration missing');
}

const app = initializeApp(window.__EDITOR_CONFIG__.firebaseOptions);
const storage = new FirebaseElectraStorage(getFirestore(app), getStorage(app));

const root = createRoot(appElement);
const App = makeApp(storage, getAuth(app), {
  electraOneProductPictureUrl,
  ...window.__EDITOR_CONFIG__,
});

delete window.__EDITOR_CONFIG__;

document.body.className += defaultThemeClassName;

root.render(<App />);
window.document.title = 'E1 | App';
